<template>
  <v-dialog v-model="show" transition="dialog-top-transition" max-width="600">
    <v-overlay absolute :value="loading">
      <v-progress-circular indeterminate color="#137547"></v-progress-circular>
    </v-overlay>
    <v-card elevation="10" max-height="1000" max-width="600">
      <p class="dialogTitle">
        Da li ste sigurni da želite da obrišete korisnika
        {{ `${this.userForDeletion.firstName} ${this.userForDeletion.lastName}` }}?
      </p>
      <p class="errorMessage">{{ this.errorMessage }}</p>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" text class="mr-2" @click="show = false"> Odustani </v-btn>
        <v-btn color="success" @click="deleteUser"> Obriši </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";

export default {
  name: "DeleteUser",
  data() {
    return {
      loading: false,
      errorMessage: "",
    };
  },
  methods: {
    async deleteUser() {
      if (this.userForDeletion.id > -1) {
        this.loading = true;
        await axios
          .delete(`User/${this.userForDeletion.id}`)
          .then(() => {
            this.$emit("userIsDeleted");
            this.loading = false;
            this.show = false;
          })
          .catch(() => {
            this.loading = false;
            this.errorMessage = "Došlo je do greške. Pokušajte ponovo kasnije.";
            setTimeout(() => (this.errorMessage = ""), 3000);
          });
      }
    },
  },
  props: {
    value: Boolean,
    user: {},
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    userForDeletion: {
      get() {
        return this.user;
      },
      set() {
        //
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card {
  overflow: hidden;
  padding: 1.5rem;
}

.closeIcon {
  display: flex;
  justify-content: flex-end;
}

.forgottenPassword {
  color: #137547;
}
.noAccountQuestion {
  border-top: 2px solid #137547;
  margin-top: 3rem;
  margin-bottom: 1.5rem;
  padding-top: 1.5rem;
}

.rounded-card {
  border-radius: 50px;
}

.dialogTitle {
  font-size: 1.5rem;
  text-align: center;
}

.dialogText {
  text-decoration: underline;
}

.errorMessage {
  color: red;
  text-align: center;
}
</style>
